/* .container{
    height: 500px;
    background: linear-gradient(180deg, #6C5FD4 23.53%, #FFFFFF 150.05%),
    linear-gradient(89.92deg, rgba(67, 157, 224, 0.3) 0.01%, rgba(110, 64, 189, 0.3) 24.98%, rgba(143, 89, 228, 0.3) 49.94%, rgba(95, 4, 210, 0.3) 74.91%, rgba(206, 27, 230, 0.3) 99.88%);
    text-align: center;
} */


.container {
    text-align: center;
    padding: 50px 20px;
    background: linear-gradient(135deg, rgba(110, 64, 189, 0.3) , rgba(67, 157, 224, 0.3), rgba(95, 4, 210, 0.3), rgba(206, 27, 230, 0.3));
}

.heading {
    font-size: 32px;
    font-weight: bold;
    color: white;
    margin-bottom: 30px;
}

.swiper {
    width: 100%;
    padding-bottom: 40px;
}

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    position: relative;
    width: 280px;
    overflow: hidden;
    /* background: white; */
    transition: transform 0.3s ease;
}

.card:hover {
    transform: scale(1.05);
}

.image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 12px;
}

.overlayPlayIcon {
    position: absolute;
    top: 40%;
    left: 40%;
    cursor: pointer;
}

.playIcon {
    width: 50px;
    height: 50px;
}


.overlayAudioIcon{
    position: absolute;
    bottom: 20%;
    right: 10%;
    cursor: pointer;
}

.audioIcon{
    width: 30px;
    height: 30px;
}



.name {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    color: rgb(64, 62, 62);
}

.designation {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
}