h2{
    font-size: 34px;
    text-transform: uppercase;
    font-weight: 100;
    line-height: 2;
    color: #2c3e50;
  }

  p{
    margin-bottom: 2rem;
    line-height: 2;
    color: #7f8c8d;
  }
  .wrapper{
    width: 800px;
    margin: 0 auto;
  }
  section{
    background-color: #fff;
    @include border-radius(4px);
    @include box-shadow(0 1px 0 rgba(0,0,0,.2));
  }
  #steezy{
    padding: 12px;
  }